import { motion } from "framer-motion";

export default function About() {
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const containerVariants = {
    hidden: {},
    visible: {
      transition: {
        staggerChildren: 0.3,
        delayChildren: 0.5,
      },
    },
  };

  return (
    <section className="py-40 px-6">
      <div className="max-w-4xl mx-auto grid lg:grid-cols-2 items-center lg:gap-6">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
        >
          <motion.h2
            className="text-6xl md:text-8xl font-bold mb-2"
            variants={textVariants}
            transition={{ duration: 0.5 }}
          >
            About
          </motion.h2>
          <motion.p
            className="text-gray-600"
            variants={textVariants}
            transition={{ duration: 0.5 }}
          >
            Restaurant investments, Digital assets and technology investments
          </motion.p>
        </motion.div>

        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          variants={containerVariants}
          className="mt-8 lg:mt-0 lg:pl-8"
        >
          <motion.div
            variants={textVariants}
            transition={{ delay: 1, duration: 0.5 }}
            className="grid grid-cols-1 gap-4 text-sm text-gray-600"
          >
            <motion.p variants={textVariants}>
              Sachin Ramrakhani, founder and managing partner of Lotus
              Investments, highlights a distinguished track record, having
              established a reputation in the restaurant industry before
              transitioning to the digital asset space, where he made strategic
              investments in Bitcoin and Ethereum since 2016.
            </motion.p>
            <motion.p variants={textVariants}>
              As we continue to grow and evolve, Lotus Investments remains
              committed to identifying and supporting visionary entrepreneurs
              and projects that transform the hospitality and technology
              landscapes.
            </motion.p>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
}
