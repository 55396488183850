export const siteData = {
  title: "Lotus Investments",
  description:
    "Boutique family office specializing in investments in hospitality, technology, and digital assets.",
  ogImage: "/lotus.png",
  socials: {
    email: "Sachinramrakhani@gmail.com",
    twitter: "@sachramrakhani",
    telegram: "@sachram",
    discord: "Sach1049",
  },
};
