// src/components/IntroLoader.js
import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { ReactComponent as Lotus } from "../assets/lotus.svg"; // Import SVG as React Component

const IntroLoader = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsVisible(false);
      document.body.style.overflow = "auto"; // Enable scrolling after animation
    }, 2500); // Display + fade-out duration: 1s display + 1.5s fade-out

    return () => clearTimeout(timeout);
  }, []);

  return (
    isVisible && (
      <motion.div
        className="fixed inset-0 flex items-center justify-center bg-white z-50"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0 }}
        transition={{ duration: 1.5, delay: 1 }} // 1s display before fading out
        onAnimationComplete={() => setIsVisible(false)}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <Lotus className="w-24 h-24 md:w-48 md:h-48" />
        </motion.div>
      </motion.div>
    )
  );
};

export default IntroLoader;
