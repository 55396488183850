import { motion } from "framer-motion";

const Section = ({ title, items, delayStart }) => {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      variants={{
        hidden: {},
        visible: {
          transition: {
            delayChildren: delayStart || 0,
            staggerChildren: 0.2,
          },
        },
      }}
      className="grid grid-cols-1 gap-4"
    >
      <motion.div
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        transition={{ duration: 0.5 }}
      >
        <h4 className="text-3xl">{title}</h4>
      </motion.div>

      {items.map((item, index) => (
        <motion.div
          key={index}
          variants={{
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 },
          }}
          transition={{ duration: 0.5 }}
        >
          <div className="">
            <h3 className="text-md font-md">
              <span className="mr-2 text-xl">//</span>
              <span>{item.title}</span>
            </h3>
            <p className="text-gray-600 text-sm">{item.description}</p>
          </div>
        </motion.div>
      ))}
    </motion.div>
  );
};

export default function Portfolio() {
  const portfolioTechItems = [
    {
      title: "Aura AI",
      description: "Create and monetize AI agents based on your existing social data.",
      link: "https://Aura.fun",
    },
  ];

  const portfolioFoodItems = [
     {
      title: "Gelso and Grand (NYC)",
      description: "Home style authentic family Italian in the heart of Little Italy.",
      link: "https://gelsoandgrand.com",
    },
    {
      title: "JaJaJaJa Mexicana (NYC and Miami)",
      description: " Authentic Mexican Vegan Cuisuine",
      link: "https://jajajamexicana.com",
    },
    {
      title: "Jealous Fork Restaurant (Kendall, Florida)",
      description: "New american dishes with creative twists in a trendy setting",
      link: "https://jealousfork.com",
    },
    {
      title: "Wonder Food",
      description: "We partner with top restaurants nationwide to bring fine dining to your doorstep",
      link: "https://wonder.com",
    },
  ];

  return (
    <section className="bg-gray-100 py-40 px-6">
      <div className="max-w-4xl mx-auto grid lg:grid-cols-2 items-center lg:gap-6">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.5 }}
        >
          <div className="mb-8 lg:mb-0">
            <h2 className="text-6xl md:text-8xl font-bold mb-2">Portfolio</h2>
            <p className="text-gray-600">
              Our portfolio showcases a curated selection of innovative ventures
            </p>
          </div>
        </motion.div>

        <div className="pl-8">
          <Section title="Tech" items={portfolioTechItems} delayStart={0.5} />

          <motion.div
            className="border-t border-gray-400 my-8"
            initial={{ opacity: 0, scaleX: 0 }}
            whileInView={{ opacity: 1, scaleX: 1 }}
            viewport={{ once: true }}
            transition={{
              duration: 0.5,
              delay: 0.5 * (1 + portfolioTechItems.length),
            }}
            style={{ transformOrigin: "left" }}
          ></motion.div>

          <Section
            title="Eats"
            items={portfolioFoodItems}
            delayStart={0.5 * (2 + portfolioTechItems.length)}
          />
        </div>
      </div>
    </section>
  );
}
