import { motion } from "framer-motion";

export default function Hero() {
  const textVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div className="relative grid min-h-screen w-screen p-8 pb-12">
      <motion.div
        className="h-full flex flex-col items-center justify-center relative w-full max-w-screen overflow-hidden"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={{
          hidden: {},
          visible: {
            transition: {
              staggerChildren: 0.25,
              delayChildren: 2.5,
            },
          },
        }}
      >
        <motion.h2
          className="text-6xl md:text-8xl font-bold mb-6"
          variants={textVariants}
          transition={{ duration: 0.7 }}
        >
          Lotus
        </motion.h2>

        <motion.div
          className="text-gray-600 max-w-screen md:w-[420px] px-4 w-full text-center "
          variants={textVariants}
          transition={{ duration: 0.7 }}
        >
          Boutique family office specializing in investments in hospitality,
          technology, and digital assets.
        </motion.div>
        <motion.div
          className="text-gray-600 max-w-screen px-4  md:w-[420px] text-center "
          variants={textVariants}
          transition={{ duration: 5 }}
        >
          <div class="floating-element"></div>
        </motion.div>
      </motion.div>
    </div>
  );
}
