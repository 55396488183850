import { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import Hero from "./components/Hero";
import Portfolio from "./components/Portfolio";
import About from "./components/About";
import LoadingAnimation from "./components/LoadingAnimation";
import { Helmet } from "react-helmet";
import { siteData } from "./data";

export default function App() {
  const { title, description, ogImage } = siteData;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={ogImage} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <div className="">
        <LoadingAnimation />

        <Header />
        <Hero />
        <Portfolio />
        <About />
        <Footer />
      </div>
    </>
  );
}
