import { siteData } from "./../data";
import { Typography } from "@material-tailwind/react";
import { RiTwitterXLine } from "react-icons/ri";
import { RiDiscordLine } from "react-icons/ri";
import { RiTelegram2Line } from "react-icons/ri";

const currentYear = new Date().getFullYear();

export function Footer() {
  const { socials } = siteData;

  return (
    <footer className="w-full fixed bottom-0 bg-white">
      <div className="mx-auto w-full max-w-7xl px-8">
        <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
          <Typography
            variant="small"
            className="mb-4 text-center font-normal text-blue-gray-900 md:mb-0"
          >
            &copy; {currentYear} Lotus Investments. All Rights Reserved.
          </Typography>
          <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
            <Typography
              as="a"
              target="_blank"
              href={`https://t.me/${socials.telegram}`}
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <RiTelegram2Line className="h-6 w-6" />
            </Typography>
            <Typography
              as="a"
              href={`https://discord.com/users/${socials.discord}`}
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <RiDiscordLine className="h-6 w-6" />
            </Typography>
            <Typography
              as="a"
              target="_blank"
              href={`https://twitter.com/${socials.twitter}`}
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <RiTwitterXLine className="h-6 w-6" />
            </Typography>
            <Typography
              as="a"
              target="_blank"
              href={`mailto:${socials.email}`}
              className="opacity-80 transition-opacity hover:opacity-100"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="size-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                />
              </svg>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
}
