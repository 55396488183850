import React from "react";
import { Navbar, Collapse, Typography } from "@material-tailwind/react";
import { ReactComponent as Lotus } from "../assets/lotus.svg"; // Import SVG as React Component
import { siteData } from "../data";

function NavList() {
  const { socials } = siteData;
  return (
    <ul className="my-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">
      <Typography
        as="li"
        variant="small"
        color="blue-gray"
        className="p-1 font-medium"
      >
        <a
          href={`mailto:${socials.email}`}
          target="_blank"
          className="flex items-center hover:text-gray-500 transition-colors"
          rel="noreferrer"
        >
          Contact
        </a>
      </Typography>
    </ul>
  );
}

export function Header() {
  const [openNav, setOpenNav] = React.useState(false);

  const handleWindowResize = () =>
    window.innerWidth >= 960 && setOpenNav(false);

  React.useEffect(() => {
    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="fixed w-full bg-gradient-to-b from-white to-transparent z-10">
      <Navbar className="mx-auto max-w-screen-xl !backdrop-blur-none !backdrop-saturate-100 px-6 py-3 border-0 !bg-transparent shadow-none blur-none">
        <div className="flex items-center justify-between text-blue-gray-900">
          <div onClick={scrollToTop} className="mr-4 cursor-pointer py-1.5">
            <Lotus className="h-16 w-16" />
          </div>
          <div>
            <NavList />
          </div>
          {/* <IconButton
          variant="text"
          className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
          ripple={false}
          onClick={() => setOpenNav(!openNav)}
        >
          {openNav ? (
            <XMarkIcon className="h-6 w-6" strokeWidth={2} />
          ) : (
            <Bars3Icon className="h-6 w-6" strokeWidth={2} />
          )}
        </IconButton> */}
        </div>
        <Collapse open={openNav}>
          <NavList />
        </Collapse>
      </Navbar>
    </div>
  );
}
